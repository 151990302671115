*, *:before, *:after {
  box-sizing: border-box;
}

html, body, #root, .App {
  height: auto;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}

.App {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

header, footer, main {
  padding: 10px;
  width: 100%;
}

header {
  z-index: 1;
}
.header-title {
  font-size: 1.7rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.header-subtitle {
  font-size: 1.3rem;
}
.header-github-link {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
}
.header-gear-icon {
  position: absolute;
  top: 5vh;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0.3;
  margin: 0 auto;
}
@media (prefers-reduced-motion: no-preference) {
  .header-gear-icon {
    animation: spin infinite 20s linear;
  }
}

main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

main .file {
  width: 80vw;
  max-width: 400px;
  display: block;
}

main .file-container {
  padding: 20px;
  margin: 10px;
  display: inline-block;
}
main .input-file-container {
  margin: 10px auto 20px;
}

main .upload-gif-button {
  width: 100%;
  max-width: 400px;
  height: 80px;
}

main .converting-file-placeholder {
  height: 40vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
main .converting-file-progress {
  margin-left: 10px;
}

.code-explainer-container {
  width: 100%;
  margin-bottom: 30px;
}

footer {
  padding-top: 0;
}
footer ul {
  margin: 0;
  padding: 20px;
  text-align: left;
  list-style: none;
  display: inline-block;
}
footer ul li {
  margin: 10px auto;
}

hr {
  border-color: #37474f;
  width: 100%;
  margin-top: 20px;
}

.lazy-load-styles-button {
  margin: 50px auto !important;
}

@media screen and (min-width: 768px) {
  .header-github-link svg {
    font-size: 2.5rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
