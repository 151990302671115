.converted-videos-container {
    padding: 40px 0;
    height: 100%;
    width: 100%;
    overflow-x: auto;
}
.converted-video-container {
    position: relative;
}
.converted-video {
    display: block;
    margin: 0 auto;
}
.converted-video-details {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.download-all-chip-container {
    position: relative;
}
.download-all-chip-progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}