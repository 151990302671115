.code-block {
    width: 100%;
    max-width: 560px;
    margin: 20px auto;
    position: relative;
    font-size: 0.85rem;
}

.code-block pre {
    text-align: initial;
    width: 100%;
    margin: 0;
}

.code-block pre code {
    border-radius: 4px;
    padding: 1.2em;
}

.copy-to-clipboard-button {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
}